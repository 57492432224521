import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSpotify} from '@fortawesome/free-brands-svg-icons'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faGithub} from '@fortawesome/free-brands-svg-icons'

const links = {
  spotify: {
    href: 'https://open.spotify.com/artist/2ZmsHRFwH3sGTrarxwgK9O',
    icon: faSpotify,
  },
  twitter: {
    href: 'https://twitter.com/richsoni',
    icon: faTwitter,
  },
  facebook: {
    href: 'https://www.facebook.com/richsonimusic/',
    icon: faFacebook,
  },
  instagram: {
    href: 'https://instagram.com/richsoni',
    icon: faInstagram,
  },
  github: {
    href: 'https://github.com/richsoni',
    icon: faGithub,
  },
}

const buildLink = ({href, icon}) => {
  return ({style = {}}) => (
    <a style={style} href={href}>
      <FontAwesomeIcon icon={icon}/>
    </a>
  );
}

export default {
  Spotify: buildLink(links.spotify),
  Twitter: buildLink(links.twitter),
  Facebook: buildLink(links.facebook),
  Instagram: buildLink(links.instagram),
  Github: buildLink(links.github),
}
