import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "./logo"
import { Layout, Menu, Affix } from "antd"
import { capitalize } from "lodash"
import CallToAction from "./CallToAction"
import { MenuOutlined } from "@ant-design/icons"
import useTopNav from "@richsoni/gatsby-theme-core/src/lib/useTopNav"

const { Header, Content, Footer } = Layout

const HeaderFooterLayout = ({ currentPath = "", children, style }) => {
  const menuItems = useTopNav().map(({ pathPrefix }) => pathPrefix)
  return (
    <>
      <CallToAction style={{}} />
      <Affix>
        <Header className="header" style={{ padding: 0, height: "auto" }}>
          <Menu
            theme="light"
            mode="horizontal"
            style={{ lineHeight: "64px", paddingTop: "1em" }}
            overflowedIndicator={
              <MenuOutlined
                style={{
                  fontSize: "20px",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "20px",
                  padding: ".5em",
                  margin: "1em auto",
                }}
              />
            }
            selectedKeys={[
              menuItems.find(
                mi => currentPath.split("/").filter(x => x)[0] === mi
              ),
            ]}
          >
            <Menu.Item key="logo" style={{ borderBottom: "none" }}>
              <Link to="/">
                <div style={{ width: 145, marginTop: "-65px" }}>
                  <Logo />
                </div>
              </Link>
            </Menu.Item>
            {menuItems.map(item => (
              <Menu.Item key={item}>
                <Link to={`/${item}`}>{capitalize(item)}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Header>
      </Affix>
      <Layout>
        <Content style={{ padding: 0 }}>
          <div style={{ padding: 24, minHeight: 280, ...style }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Rich Soni, LLC ©{new Date().getFullYear()} Created by Rich Soni
        </Footer>
      </Layout>
    </>
  )
}

HeaderFooterLayout.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
}

HeaderFooterLayout.defaultProps = {
  style: {},
}

export default HeaderFooterLayout
