import { useStaticQuery, graphql } from "gatsby";

export default () => {
  return useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          topNavigation {
            pathPrefix
          }
        }
      }
    }
  `).site.siteMetadata.topNavigation;
};
