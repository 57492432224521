import React from "react";
import PropTypes from "prop-types"

const Form = (props) => {
  const {children, style, className} = props;
  return (
    <form
      action="//richsoni.us8.list-manage.com/subscribe/post?u=bc85e50b336a97670d097c9d0&amp;id=cd363f3412"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
      className={className}
      style={style}
    >
      {children}
      <div style={{
        position: 'absolute',
        left: -5000,
      }}>
        <input type="text" name="b_bc85e50b336a97670d097c9d0_cd363f3412" tabIndex={-1} defaultValue="" />
      </div>
    </form>
  )};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Form.defaultProps = {
  className: '',
  style: {},
};

const Email = (props) => {
  return <input
    className={props.className}
    style={props.style}
    type="email"
    name="EMAIL"
    placeholder="ENTER YOUR EMAIL"
  />
}

Email.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

Email.defaultProps = {
  className: '',
  style: {},
};

export default class Mailchimp {
  static Form = Form;
  static Email = Email;
};
