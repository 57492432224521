import React from "react"
import Mailchimp from './Mailchimp';
import ExternalLinks from './ExternalLinks';
import { Button } from 'antd';

const linkStyle = {
  padding: '0px 10px',
};

const MailchimpForm = ({style = {}}) => (
  <Mailchimp.Form style={{
    display: 'flex',
    flexFlow: 'row wrap',
    ...style,
  }}>
    <Mailchimp.Email style={{
      background: 'transparent',
      flexGrow: 3,
      border: 'none',
      outline: 'none',
      borderBottom: '2px solid #fff',
    }} />
    <Button style={{
      flexGrow: 1,
      margin: '.5em',
      boxSizing: 'border-box',
    }}
    ghost htmlType='submit'>Subscribe</Button>
  </Mailchimp.Form>
)

export default ({style = {}}) => {
  return (
    <div style={{
      display: 'flex',
      flexFlow: 'row wrap',
      color: 'white',
      background: 'black',
      fontSize: 14,
      alignItems: 'center',
      padding: '20px 50px',
      justifyContent: 'space-between',
      ...style,
    }}>
      <MailchimpForm style={{
        flexGrow: 2,
      }}/>
      <div style={{
          fontSize: '2em',
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'flex-end',
          flexGrow: 4,
          ...style,
        }}>
          <ExternalLinks.Facebook style={linkStyle} />
          <ExternalLinks.Github style={linkStyle} />
          <ExternalLinks.Instagram style={linkStyle} />
          <ExternalLinks.Twitter style={linkStyle} />
          <ExternalLinks.Spotify style={linkStyle} />
      </div>
    </div>
  )
}
